import React, { FC, useMemo } from 'react';
import { CurrentUser, ForgotPasswordParams, Profile } from '@modules/auth/model';
import { Page, PageProps } from '@layout/page';
import { useExternalSubmitButton } from '@shared/modules/form/hooks';
import { SharedButton } from '@styles/shared';
import { useSendTask } from '@core/http/hooks';

import * as AuthService from './service';
import { EnhanceFormik, mapToPreventLeaveResult } from '@shared/modules/form';
import ProfileForm from '@modules/auth/components/profile/ProfileForm';
import { useAuthContext } from '@modules/auth/context';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';

interface ProfilePageProps {
  profile: Profile;
}

const ProfilePage: FC<ProfilePageProps> = ({ profile }) => {
  const { formRef, handleSubmit } = useExternalSubmitButton();

  const { requestUpdateProfile } = useAuthContext();

  const [loading, updateProfile] = useSendTask(AuthService.updateUserProfile);

  const forgotPasswordParams = useMemo<ForgotPasswordParams>(() => ({ email: profile.email }), [profile.email]);

  const [resetPasswordLoading, resetPassword] = useSendTask(
    AuthService.forgotPassword,
    {
      successMessage: "Un e-mail vient d'être envoyé avec la procédure à suivre pour choisir un nouveau mot de passe.",
    },
    forgotPasswordParams,
  );

  const pageProps: PageProps = {
    title: 'Mon compte',
    bottom: {
      left: (
        <SharedButton
          icon="key"
          labelPosition="left"
          content="Réinitialiser le mot de passe"
          loading={resetPasswordLoading}
          onClick={resetPassword}
        />
      ),
      right: <SharedButton btnType="save" onClick={handleSubmit} loading={loading} />,
    },
  };

  const handleUpdate = (user: CurrentUser) =>
    pipe(
      () => updateProfile(user),
      TE.chainIOK(() => requestUpdateProfile),
      mapToPreventLeaveResult(),
    )();

  return (
    <Page {...pageProps}>
      <EnhanceFormik
        ref={formRef}
        initialValues={{ email: profile.email, firstname: profile.firstname, lastname: profile.lastname }}
        onSubmit={handleUpdate}
        preventLeave>
        <ProfileForm />
      </EnhanceFormik>
    </Page>
  );
};

export default ProfilePage;
