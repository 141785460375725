import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '@styles/utils';
import { Popup } from 'semantic-ui-react';

export const NavContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: #2e2e2e;
  overflow: hidden;
`;

export const NavHeader = styled(Link)`
  display: block;
  margin-bottom: 7px;
  padding: 20px 40px 10px;

  > img {
    width: 100%;
  }
`;

export const NavContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding-right: 10px;
`;

export const NavGroupHeader = styled.div`
  padding: 5px 14px;
  background: #3f3d3c;
  color: #9f9890;
  text-transform: uppercase;

  > h5 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
  }
`;

export const NavGroupContent = styled.ul`
  padding: 2px 14px 18px;
  margin: 0;
  list-style: none;

  li a {
    display: block;
    margin: 6px 0;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);

    &.active {
      color: ${colors.primary};
    }
  }
`;

export const NavBottom = styled.div`
  padding: 14px;

  > p:first-child {
    margin: 0;
    padding-bottom: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const NavBottomContent = styled.div`
  display: flex;
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  > p {
    flex: 1 1 auto;
    margin: 0;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NavBottomPopup = styled(Popup)`
  &.ui.popup {
    padding: 0;
  }
`;

export const NavBottomWarning = styled.div`
  display: flex;
  padding-bottom: 8px;
  align-items: center;

  > p {
    margin: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
  }

  > i {
    position: relative;
    top: -3px;
    margin-right: 6px;
  }
`;
