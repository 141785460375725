import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#e64b3c',
    primaryDark: '#cd2a1a',
  },
};

export default theme;
