import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  height: 100%;
  overflow: hidden;
`;

export const LayoutContent = styled.div`
  position: relative;
  overflow: hidden;
`;
