import { Newtype } from 'newtype-ts';
import { CompanyUserId } from '@modules/users/model';

export type ResetPasswordToken = Newtype<{ readonly ID: unique symbol }, string> & string;
export type ActivationToken = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum UserRole {
  CovergardenAdmin = 'covergarden_admin',
  SupplierAdmin = 'supplier_admin',
  SupplierTech = 'supplier_tech',
  SupplierCommunication = 'supplier_communication',
  SupplierMarketing = 'supplier_marketing',
  LandscaperAdmin = 'landscaper_admin', // currently has not access to BO
  FredonAdmin = 'fredon_admin',
  UnepAdmin = 'unep_admin',
  AgrefAdmin = 'agref_admin',
}

export enum CompanyKind {
  SupplierProducer = 'supplier_producer',
  SupplierProvider = 'supplier_provider',
  Landscaper = 'landscaper',
  Greenkeeper = 'greenkeeper',
  Collectivity = 'collectivity',
  Covergarden = 'covergarden',
  Undefined = 'undefined',
  Agref = 'agref',
  Fredon = 'fredon',
  Unep = 'unep',
}

export enum CompanyUserRole {
  Admin = 'admin',
  Tech = 'tech',
  Communication = 'communication',
  Marketing = 'marketing',
}

export interface AvailableUserProfile {
  id: CompanyUserId;
  role: UserRole;
  company_label: string;
  company_kind: CompanyKind;
  company_user_role: CompanyUserRole;
}

export interface UserProfile extends AvailableUserProfile {
  is_sudo: boolean;
}

export interface CurrentUser {
  email: string;
  firstname: string;
  lastname: string;
}

export type Profile = UserProfile & CurrentUser;
export interface AuthenticateParams {
  login: string;
  password: string;
}

export interface ForgotPasswordParams {
  email: string;
}

export interface PasswordsParams {
  password: string;
  passwordConfirm: string;
}

export interface UpdateAffiliationParams {
  token: string;
  affiliation: boolean;
}

export interface ActivationResult {
  type: 'admin' | 'mobile';
}
