import styled from 'styled-components';
import { Menu, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { colors } from '@styles/utils';

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageHeader = styled(Segment)`
  &.ui.vertical.segment {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    background: #ffffff;
    min-height: 48px;

    > .ui.header {
      margin: 0;
      font-weight: 500;
    }
  }
`;

export const PageHeaderDivider = styled.div`
  flex: 1 1 auto;
`;

export const PageBreadCrumbLink = styled(Link)`
  margin-right: 3px;
  font-size: 14px;
  font-style: italic;
  color: ${colors.primary};
  transition: color 0.15s linear;

  &:hover {
    color: ${colors.primaryDark};
  }
`;

export const PageTabs = styled(Menu)`
  &.ui.secondary.pointing.menu {
    margin-bottom: 14px;

    > .item {
      &.active {
        letter-spacing: -0.15px;
      }
    }
  }
`;

export const PageContent = styled.div<{ $noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: ${props => (props.$noPadding ? '0' : '14px')};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PageBottomBar = styled(Segment)`
  &.ui.segment {
    margin: 0;
    padding: 12px 14px 10px;
    border-radius: 0;

    > div {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
  }
`;

export const PageBottomBarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;

  .ui.button {
    margin: 0 7px 0 0 !important;
  }
`;

export const PageBottomBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;

  .ui.button {
    margin: 0 0 0 7px !important;
  }
`;
