import React, { FC, useLayoutEffect, useState } from 'react';
import { constVoid } from 'fp-ts/function';
import { Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const API_DOWN_EVENT_NAME = 'api-down';
const API_UP_EVENT_NAME = 'api-up';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .loader {
    &:before {
      border-color: ${props => props.theme.colors.primary} !important;
    }
  }

  p {
    padding-top: 20px;
  }
`;

const ApiDownIndicator: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(API_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(API_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(API_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(API_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return (
    <Modal open={opened} size="mini" onClose={constVoid}>
      <Modal.Header>Connexion avec le serveur interrompue</Modal.Header>

      <Modal.Content>
        <Content>
          <Loader size="large" inline active />

          <p>Veuillez patienter nous essayons de rétablir la connexion.</p>
        </Content>
      </Modal.Content>
    </Modal>
  );
};

export function showApiDownIndicator() {
  window.dispatchEvent(new Event(API_DOWN_EVENT_NAME));
}

export function hideApiDownIndicator() {
  window.dispatchEvent(new Event(API_UP_EVENT_NAME));
}

export default ApiDownIndicator;
