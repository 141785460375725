import React, { FC, ReactNode } from 'react';

import * as Styled from './Page.styles';
import { Header, Menu } from 'semantic-ui-react';
import { renderNullable, renderOptional } from '@shared/utils/render';

import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import Seo from '@shared/components/seo/Seo';
import { NavLink } from 'react-router-dom';
import Notifications from '@modules/notifications/components/Notifications';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: string;
  show?: boolean;
  exact?: boolean;
}

export interface BreadCrumb {
  title: string;
  to: string;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

const Tabs: FC<{ tabs?: Array<Tab> }> = ({ tabs: receivedTabs }) => {
  const tabs = pipe(
    O.fromNullable(receivedTabs),
    O.chain(tabs =>
      pipe(
        tabs,
        A.filter(tab => tab.show !== false),
        NEA.fromArray,
      ),
    ),
  );

  return renderOptional(tabs, tabs => (
    <Styled.PageTabs pointing secondary>
      {tabs.map(tab => (
        <Menu.Item key={tab.to} as={NavLink} to={tab.to} content={tab.title} exact={tab.exact} />
      ))}
    </Styled.PageTabs>
  ));
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottomBar>
        <div>
          <Styled.PageBottomBarLeft>{O.toNullable(leftContent)}</Styled.PageBottomBarLeft>
          <Styled.PageBottomBarRight>{O.toNullable(rightContent)}</Styled.PageBottomBarRight>
        </div>
      </Styled.PageBottomBar>
    );
  }

  return null;
};

export interface PageProps {
  title?: ReactNode;
  seoTitle?: string;
  breadcrumbs?: BreadCrumb | Array<BreadCrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  top?: ReactNode;
  noPadding?: boolean;
}

export const Page: FC<PageProps> = ({
  title,
  seoTitle: receivedSeoTitle,
  breadcrumbs,
  tabs,
  bottom,
  top,
  noPadding,
  children,
}) => {
  const crumbs = pipe(
    O.fromNullable(breadcrumbs),
    O.chain(breadcrumbs => (Array.isArray(breadcrumbs) ? NEA.fromArray(breadcrumbs) : O.some([breadcrumbs]))),
  );

  const seoTitle = pipe(
    O.fromNullable(receivedSeoTitle),
    O.alt(() =>
      pipe(
        O.fromNullable(title),
        O.filterMap(title => (typeof title === 'string' ? O.some(title) : O.none)),
      ),
    ),
    O.toUndefined,
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      <Styled.PageHeader vertical>
        {renderOptional(crumbs, crumbs =>
          crumbs.map((crumb, i, crumbs) => (
            <Styled.PageBreadCrumbLink key={crumb.title} to={crumb.to}>
              {crumb.title}
              {i === crumbs.length - 1 && !title ? '' : ` - `}
            </Styled.PageBreadCrumbLink>
          )),
        )}

        {renderNullable(title, title => (
          <Header as="h3">{title}</Header>
        ))}

        <Styled.PageHeaderDivider />

        {top}

        <Notifications />
      </Styled.PageHeader>

      <Styled.PageContent id={PAGE_SCROLLER_ID} $noPadding={noPadding}>
        <Tabs tabs={tabs} />

        {children}
      </Styled.PageContent>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};
