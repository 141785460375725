import { CompanyKind, Profile, UserRole } from '@modules/auth/model';
import { constTrue, pipe } from 'fp-ts/function';
import { concatAll, Semigroup } from 'fp-ts/Semigroup';
import * as Function from 'fp-ts/function';
import * as Boolean from 'fp-ts/boolean';
import * as O from 'fp-ts/Option';
import { Predicate } from 'fp-ts/Predicate';

export function userRolePredicate(...roles: Array<UserRole>): Predicate<Profile> {
  return profile => roles.includes(profile.role);
}

export function userRolePredicateOpt(...roles: Array<UserRole>): Predicate<O.Option<Profile>> {
  return profile => pipe(profile, O.exists(userRolePredicate(...roles)));
}

export function companyKindPredicate(...kinds: Array<CompanyKind>): Predicate<Profile> {
  return profile => kinds.includes(profile.company_kind);
}

export function companyKingPredicateOpt(...kinds: Array<CompanyKind>): Predicate<O.Option<Profile>> {
  return profile => pipe(profile, O.exists(companyKindPredicate(...kinds)));
}

export function combinePredicates<T>(...predicates: Array<Predicate<T>>): Predicate<T> {
  const pSemiGroup: Semigroup<Predicate<T>> = Function.getSemigroup(Boolean.SemigroupAll)();

  return profile => concatAll(pSemiGroup)(constTrue)(predicates)(profile);
}
