import { PureComponent } from 'react';
import { FormikConnectProps } from '../model';
import { FormikValues, connect, FormikErrors } from 'formik';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { constVoid, Lazy, pipe } from 'fp-ts/function';

class FormikScrollingError extends PureComponent<FormikConnectProps<FormikValues>> {
  componentDidUpdate(prevProps: Readonly<FormikConnectProps<FormikValues>>): void {
    if (prevProps.formik.isSubmitting && !this.props.formik.isSubmitting && !this.props.formik.isValid) {
      this.scrollToError();
    }
  }

  /**
   * Try to get first error key flatten
   *
   * @param errors
   * @param key
   */
  private getFirstErrorKey = (
    errors: FormikErrors<FormikValues> | FormikErrors<any>[] | string | string[] | null | undefined,
    key: string = '',
  ): O.Option<string> => {
    return pipe(
      O.fromNullable(errors),
      O.chain(e => {
        // If value is string return key
        if (typeof e === 'string') {
          return O.some(key);
        }

        // If value is Array get key recursively
        else if (Array.isArray(e)) {
          return pipe(
            e,
            A.findIndex<any>(val => !!val),
            O.chain(arrayKey => this.getFirstErrorKey(e[arrayKey], `${key}[${arrayKey}]`)),
          );
        }

        // If value is Object get key recursively
        else if (typeof e === 'object') {
          return pipe(
            O.fromNullable(Object.keys(e)),
            O.chain(A.head),
            O.chain(objectKey => this.getFirstErrorKey(e[objectKey], `${key}${key !== '' ? '.' : ''}${objectKey}`)),
          );
        }

        return O.none;
      }),
    );
  };

  private scrollToError = () => {
    const errorKey = this.getFirstErrorKey(this.props.formik.errors);

    const elementByName: O.Option<HTMLElement> = pipe(
      errorKey,
      O.chainNullableK(key => document.getElementsByName(key)),
      O.chain(elements => (elements.length > 0 ? O.some(elements[0]) : O.none)),
    );

    const elementById: Lazy<O.Option<HTMLElement>> = () =>
      pipe(
        errorKey,
        O.chainNullableK(key => document.getElementById(key)),
      );

    pipe(
      elementByName,
      O.alt(elementById),
      O.fold(
        () => constVoid,
        el => () => el.scrollIntoView({ behavior: 'smooth', block: 'center' }),
      ),
    )();
  };

  render() {
    return null;
  }
}

export default connect<unknown, FormikValues>(FormikScrollingError);
