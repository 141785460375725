import {
  AuthenticateParams,
  Profile,
  ForgotPasswordParams,
  ResetPasswordToken,
  PasswordsParams,
  ActivationToken,
  UpdateAffiliationParams,
  CurrentUser,
  ActivationResult,
} from '@modules/auth/model';
import { HttpError, httpService, HttpTask } from '@core/http';
import * as IO from 'fp-ts/IO';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/function';

const REFRESH_TOKEN_STORAGE_KEY = 'PG_REFRESH';

interface AuthenticateLockedErrorData {
  token?: string; // on HttpStatusCode.LOCKED
}
export function authenticate(
  params: AuthenticateParams,
): HttpTask<{ refreshToken: string }, AuthenticateLockedErrorData> {
  return httpService.post('/authenticate', params);
}

export function storageRefreshToken(refreshToken: string): IO.IO<void> {
  return () => localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
}

export function getRefreshTokenFromStorage(): IO.IO<O.Option<string>> {
  return () => O.fromNullable(localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY));
}

export function getProfile(): HttpTask<Profile> {
  return httpService.get<Profile>('/authenticate/profile');
}

export function logout(): HttpTask {
  return httpService.delete('/authenticate');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask {
  return httpService.post('/password/reset/request', params);
}

export function resetPassword(token: ResetPasswordToken, params: PasswordsParams): HttpTask {
  return httpService.post(`/password/reset/confirm`, params, { params: { token } });
}

export function getActivationDetail(token: ActivationToken): HttpTask<{ email: string }> {
  return httpService.get('/account/activate', { params: { token } });
}

export function activateAccount(token: ActivationToken, params: PasswordsParams): HttpTask<ActivationResult> {
  return httpService.post('/account/activate', params, { params: { token }, maxRedirects: 0 });
}

export function updateUserProfile(user: CurrentUser): HttpTask {
  return httpService.post('/user', user);
}

export function updateAccountAffiliation(params: UpdateAffiliationParams): HttpTask {
  return httpService.post('/account/update-affiliation', params);
}

export function refreshToken(): HttpTask {
  return pipe(
    T.fromIO(getRefreshTokenFromStorage()),
    TE.fromTaskOption(() => HttpError.notFound),
    TE.chain(refreshToken => httpService.post('/authenticate/refresh', { refreshToken })),
  );
}
