import { HttpError, HttpRemoteData, HttpResult, HttpStatusCode, HttpTask } from '@core/http/model';
import * as RD from 'fp-ts-remote-data';
import * as EI from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import isEqual from 'lodash.isequal';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';

function useCurrentArgs<P extends unknown[]>(...args: P) {
  const [currentArgs, setCurrentArgs] = useState(args);

  useEffect(() => {
    setCurrentArgs(old => (!isEqual(old, args) ? args : old));
  }, [args]);

  return currentArgs;
}

export function useFetchTask<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<R, E>,
  ...args: P
): [HttpRemoteData<R, E>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<HttpRemoteData<R, E>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<HttpRemoteData<R, E>>(RD.pending);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(RD.fromEither(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}

export function useFetchTaskOption<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<R, E>,
  ...args: P
): [O.Option<R>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<O.Option<R>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<O.Option<R>>(O.none);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(O.fromEither(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}

export interface UseSendTaskParams {
  hideError?: true | Array<HttpStatusCode>;
  successMessage?: ReactNode;
}

export function useSendTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  task: (...args: [...P1, ...P2]) => HttpTask<R, E>,
  { hideError, successMessage }: UseSendTaskParams = {},
  ...args: P1
): [boolean, (...args: P2) => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<HttpError<E>>>(O.none);
  const [success, setSuccess] = useState<boolean>(false);

  const debouncedSetLoading = useDebouncedCallback(setLoading, 0);

  const currentArgs = useCurrentArgs(...args);

  const sendRequest = useCallback(
    (...args2: P2) => {
      debouncedSetLoading(true);
      setError(O.none);
      setSuccess(false);

      return pipe(
        task(...[...currentArgs, ...args2]),
        T.chainIOK(res => () => {
          debouncedSetLoading(false);
          setSuccess(EI.isRight(res));
          setError(O.fromEither(EI.swap(res)));

          if (EI.isRight(res) && successMessage) {
            toast.success(successMessage);
          } else if (EI.isLeft(res)) {
            const show = pipe(
              O.fromNullable(hideError),
              O.fold(
                () => true,
                hide => (Array.isArray(hide) ? !hide.includes(res.left.status) : false),
              ),
            );

            if (show && res.left.status <= 500) {
              const message = pipe(
                O.fromNullable(res.left.message),
                O.filter(message => typeof message === 'string' && message !== ''),
                O.getOrElse(() => 'Une erreur technique est survenue'),
              );

              toast.error(message);
            } else if (res.left.status > 500) {
              const message = 'La plateforme est momentanément indisponible';

              toast.error(message);
            }
          }

          return res;
        }),
      )();
    },
    [task, currentArgs, debouncedSetLoading, hideError, successMessage],
  );

  return [loading, sendRequest, error, success];
}

export function useDeleteTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  task: (...args: [...P1, ...P2]) => HttpTask<R, E>,
  returnUrl?: string,
  params: UseSendTaskParams = {},
  ...args: P1
): [boolean, (...args: P2) => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean] {
  const history = useHistory();

  const [loading, send, error, success] = useSendTask(task, params, ...args);

  const handleSend = useCallback(
    (...args2: P2) =>
      send(...args2).then(res => {
        if (EI.isRight(res) && returnUrl) {
          history.replace(returnUrl, { ignorePrevent: true });
        }

        return res;
      }),
    [history, send, returnUrl],
  );

  return [loading, handleSend, error, success];
}
