import { css } from 'styled-components';

export const rcSliderOverwrite = css`
  .rc-slider {
    &.rc-slider-disabled {
      background-color: #fff !important;
    }

    .rc-slider-handle {
      position: relative;
      width: 20px;
      height: 20px;
      margin-top: -8px;
      background: #fff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
      border: none;
      box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%), 0 0 0 1px rgb(34 36 38 / 15%) inset;

      > p {
        position: absolute;
        top: -20px;
        left: 50%;
        color: #8e8e8e;
        font-size: 12px;
        transform: translateX(-50%);
      }
    }
  }
`;
