import React, { ComponentType, FC, useMemo } from 'react';
import { Routes } from '@core/router/Routes';

import { match, Redirect, Route, RouteProps } from 'react-router-dom';

import { safeLazy } from '@core/router/utils';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';
import Layout from '@layout/Layout';
import { CompanyKind, Profile, UserRole } from '@modules/auth/model';
import { constTrue, pipe } from 'fp-ts/function';

import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import ProfilePage from '@modules/auth/ProfilePage';
import { combinePredicates, companyKindPredicate, userRolePredicate } from '@modules/auth/utils';
import * as H from 'history';
import { NotificationContextProvider } from '@modules/notifications/context';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { not, Predicate } from 'fp-ts/Predicate';

const AuthRoutes = safeLazy(() => import('@modules/auth/routes'));

const DashBoardPage = safeLazy(() => import('@modules/dashboard/DashBoardPage'));

const RetailPointsRoutes = safeLazy(() => import('@modules/retail-points/routes'));
const ProductsRoutes = safeLazy(() => import('@modules/products/routes'));
const CatalogsRoutes = safeLazy(() => import('@modules/catalogs/routes'));
const BrandsRoutes = safeLazy(() => import('@modules/brands/routes'));

const PartnersCatalogsRoutes = safeLazy(() => import('@modules/partners-catalogs/routes'));
const PartnershipsRoutes = safeLazy(() => import('@modules/partnerships/routes'));

const ReportsRoutes = safeLazy(() => import('@modules/reports/routes'));

const NewsRoutes = safeLazy(() => import('@modules/news/routes'));

const ReportAlertsRoutes = safeLazy(() => import('@modules/report-alerts/routes'));

const CompaniesRoutes = safeLazy(() => import('@modules/companies/routes'));
const MyCompanyRoutes = safeLazy(() => import('@modules/company/routes'));
const UsersRoutes = safeLazy(() => import('@modules/users/routes'));
const ImportsRoutes = safeLazy(() => import('@modules/imports/routes'));
const ResourceManagerRoutes = safeLazy(() => import('@modules/resource-manager/routes'));
const ProductTypesRoutes = safeLazy(() => import('@modules/product-types/routes'));
const UniversesRoutes = safeLazy(() => import('@modules/universes/routes'));
const CoachRoutes = safeLazy(() => import('@modules/coach/routes'));
const SurveysRoutes = safeLazy(() => import('@modules/surveys/routes'));
const SubscriptionOffersRoutes = safeLazy(() => import('@modules/subscription-offers/routes'));
const ArchivesRoutes = safeLazy(() => import('@modules/archives/routes'));
const LegalNoticeRoutes = safeLazy(() => import('@modules/legal-notice/routes'));

export interface PlatformRoute {
  title?: string;
  path: string;
  exact?: boolean;
  component: ComponentType;
  canAccess: Predicate<Profile>;
}

function createRoutesRecord<T extends Record<string, PlatformRoute | NavEntry>>(record: T): T {
  return record;
}

export const routes = createRoutesRecord({
  dashBoard: {
    title: 'Tableau de bord',
    path: '/',
    exact: true,
    component: DashBoardPage,
    canAccess: userRolePredicate(
      UserRole.SupplierAdmin,
      UserRole.SupplierMarketing,
      UserRole.SupplierTech,
      UserRole.SupplierCommunication,
    ),
  },
  brands: {
    title: 'Marques',
    path: '/brands',
    component: BrandsRoutes,
    canAccess: combinePredicates(
      userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierTech),
      not(companyKindPredicate(CompanyKind.SupplierProvider)),
    ),
  },
  products: {
    title: 'Produits',
    path: '/products',
    component: ProductsRoutes,
    canAccess: combinePredicates(
      userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierTech, UserRole.SupplierMarketing),
      not(companyKindPredicate(CompanyKind.SupplierProvider)),
    ),
  },
  catalogs: {
    title: 'Catalogues',
    path: '/catalogs',
    component: CatalogsRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierMarketing),
  },
  retailPoints: {
    title: 'Points de vente',
    path: '/retail-points',
    component: RetailPointsRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierMarketing),
  },
  partnersCatalogs: {
    title: 'Catalogues fournisseur',
    path: '/partners/catalogs',
    component: PartnersCatalogsRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierMarketing, UserRole.LandscaperAdmin),
  },
  partnerships: {
    title: 'Partenaires',
    path: '/partnerships',
    component: PartnershipsRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierMarketing, UserRole.LandscaperAdmin),
  },
  reports: {
    path: '/reports',
    component: ReportsRoutes,
    canAccess: userRolePredicate(
      UserRole.CovergardenAdmin,
      UserRole.UnepAdmin,
      UserRole.AgrefAdmin,
      UserRole.FredonAdmin,
    ),
  },
  news: {
    path: '/news',
    component: NewsRoutes,
    canAccess: constTrue,
  },
  reportAlerts: {
    title: 'Mes alertes',
    path: '/report-alerts',
    component: ReportAlertsRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.SupplierMarketing),
  },
  companies: {
    title: 'Comptes entreprise',
    path: '/companies',
    component: CompaniesRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin, UserRole.UnepAdmin, UserRole.AgrefAdmin),
  },
  company: {
    title: 'Compte entreprise',
    path: '/company',
    component: MyCompanyRoutes,
    canAccess: userRolePredicate(UserRole.SupplierAdmin, UserRole.LandscaperAdmin),
  },
  users: {
    title: 'Utilisateurs',
    path: '/users',
    component: UsersRoutes,
    canAccess: userRolePredicate(
      UserRole.CovergardenAdmin,
      UserRole.UnepAdmin,
      UserRole.AgrefAdmin,
      UserRole.FredonAdmin,
      UserRole.SupplierAdmin,
      UserRole.LandscaperAdmin,
    ),
  },
  imports: {
    title: 'Imports',
    path: '/imports',
    component: ImportsRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin),
  },
  resourceManager: {
    title: 'Gestionnaire de ressources',
    path: '/resource-manager',
    component: ResourceManagerRoutes,
    canAccess: constTrue,
  },
  productTypes: {
    title: 'Types de produit',
    path: '/product-types',
    component: ProductTypesRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin),
  },
  universes: {
    title: 'Univers produit',
    path: '/universes',
    component: UniversesRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin),
  },
  coach: {
    title: 'Coach',
    path: '/coach',
    component: CoachRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin),
  },
  surveys: {
    title: 'Agro participation',
    path: '/surveys',
    component: SurveysRoutes,
    canAccess: userRolePredicate(
      UserRole.CovergardenAdmin,
      UserRole.UnepAdmin,
      UserRole.AgrefAdmin,
      UserRole.FredonAdmin,
    ),
  },
  subscriptionOffers: {
    title: "Offres d'abonnement",
    path: '/subscription-offers',
    component: SubscriptionOffersRoutes,
    canAccess: userRolePredicate(UserRole.CovergardenAdmin),
  },
  archives: {
    title: 'Archives',
    path: '/archives',
    component: ArchivesRoutes,
    canAccess: userRolePredicate(
      UserRole.SupplierAdmin,
      UserRole.SupplierTech,
      UserRole.SupplierMarketing,
      UserRole.LandscaperAdmin,
    ),
  },
  legalNotice: {
    title: 'Mentions légales',
    path: '/legal-notice',
    component: LegalNoticeRoutes,
    canAccess: constTrue,
  },
});

export interface NavEntry {
  title: string;
  path: string;
  canAccess: Predicate<Profile>;
  exact?: boolean;
  isActive?: <Params>(match: match<Params> | null, location: H.Location) => boolean;
}

export interface NavGroup {
  title?: string;
  entries: Array<NavEntry>;
}

export const navEntries: Array<NavGroup> = [
  {
    entries: [routes.dashBoard],
  },
  {
    title: 'Mon offre',
    entries: [routes.brands, routes.products, routes.catalogs, routes.retailPoints],
  },
  {
    title: 'Clients & Fournisseurs',
    entries: [routes.partnersCatalogs, routes.partnerships],
  },
  {
    title: "Jardi' Alerte",
    entries: [
      {
        title: 'Signalements',
        path: '/reports',
        canAccess: userRolePredicate(
          UserRole.CovergardenAdmin,
          UserRole.UnepAdmin,
          UserRole.AgrefAdmin,
          UserRole.FredonAdmin,
        ),
        isActive: (_, location) => /^\/reports\/?[0-9]*$/.test(location.pathname),
      },
      {
        title: 'Cartographie',
        path: '/reports/map',
        canAccess: userRolePredicate(
          UserRole.CovergardenAdmin,
          UserRole.UnepAdmin,
          UserRole.AgrefAdmin,
          UserRole.FredonAdmin,
        ),
      },
      {
        title: 'Arborescence',
        path: '/reports/categories',
        canAccess: userRolePredicate(UserRole.CovergardenAdmin),
      },
      {
        title: 'Import',
        path: '/reports/import',
        canAccess: userRolePredicate(UserRole.CovergardenAdmin, UserRole.UnepAdmin, UserRole.AgrefAdmin),
      },
      {
        title: 'Import',
        path: '/reports/import/steps',
        canAccess: userRolePredicate(UserRole.FredonAdmin),
      },
      {
        title: 'Export',
        path: '/reports/export',
        canAccess: userRolePredicate(UserRole.CovergardenAdmin, UserRole.FredonAdmin),
      },
    ],
  },
  {
    title: 'Actualités',
    entries: [
      {
        title: "Fil d'actualités",
        path: '/news/feed',
        canAccess: constTrue,
      },
      {
        title: 'Mes actualités',
        path: '/news',
        canAccess: userRolePredicate(
          UserRole.CovergardenAdmin,
          UserRole.FredonAdmin,
          UserRole.UnepAdmin,
          UserRole.AgrefAdmin,
          UserRole.SupplierAdmin,
          UserRole.SupplierCommunication,
        ),
        isActive: (_, location) => /^\/news(\/(([0-9]*)|(new))(\/[a-z]*)?)?$/.test(location.pathname),
      },
    ],
  },
  {
    title: "Jardi' Alerte",
    entries: [routes.reportAlerts],
  },
  {
    title: 'Administration',
    entries: [
      routes.companies,
      routes.company,
      routes.users,
      routes.imports,
      routes.resourceManager,
      routes.productTypes,
      routes.universes,
      routes.coach,
      routes.surveys,
      routes.subscriptionOffers,
      routes.archives,
      routes.legalNotice,
    ],
  },
];

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => {
  const filterRoutes = useMemo(
    () =>
      pipe(
        routes,
        R.filter(route => route.canAccess(profile)),
        R.toArray,
      ),
    [profile],
  );

  const isAdmin = [UserRole.CovergardenAdmin, UserRole.UnepAdmin, UserRole.AgrefAdmin].includes(profile.role);
  const isFredon = UserRole.FredonAdmin === profile.role;

  return (
    <NotificationContextProvider profile={profile}>
      <Layout profile={profile}>
        <Routes>
          {filterRoutes.map(([key, route]) => (
            <Route key={key} path={route.path} component={route.component} exact={route.path === '/'} />
          ))}

          <Route path="/profile" exact>
            <ProfilePage profile={profile} />
          </Route>

          {isAdmin ? <Redirect path="/" to="/companies" exact /> : null}
          {isFredon ? <Redirect path="/" to="/reports" exact /> : null}

          <Redirect
            to={{
              pathname: '/',
              state: {
                ignorePrevent: true,
              },
            }}
          />
        </Routes>
      </Layout>
    </NotificationContextProvider>
  );
};

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();

  return (
    <SentryProvider profile={profile}>
      <Routes>
        <Route
          path={['/login', '/profiles', '/logout', '/pass', '/account/update-affiliation']}
          component={AuthRoutes}
        />

        {renderOptional(
          profile,
          profile => (
            <Route>
              <PrivateRoutes profile={profile} />
            </Route>
          ),
          () => (
            <Redirect to="/login" />
          ),
        )}
      </Routes>
    </SentryProvider>
  );
};

export const RestrictedRoute: FC<RouteProps & { roles: Array<UserRole> }> = ({ roles, ...props }) => {
  const { profile } = useAuthContext();

  const canAccess = pipe(
    profile,
    O.exists(profile => roles.includes(profile.role)),
  );

  if (canAccess) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          ignorePrevent: true,
        },
      }}
    />
  );
};

export default RootRoutes;
