import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { CurrentUser } from '@modules/auth/model';
import { Form, Input, Segment } from 'semantic-ui-react';
import { SubmitOnEnter } from '@shared/modules/form';

const ProfileForm: FC = () => {
  const { values, handleSubmit, handleChange } = useFormikContext<CurrentUser>();

  return (
    <Segment>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Field disabled>
          <label>E-mail</label>
          <Input value={values.email} disabled />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="firstname">Prénom</label>
            <Input
              id="firstname"
              name="firstname"
              value={values.firstname}
              placeholder="Prénom"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label htmlFor="lastname">Nom</label>
            <Input id="lastname" name="lastname" value={values.lastname} placeholder="Nom" onChange={handleChange} />
          </Form.Field>
        </Form.Group>

        <SubmitOnEnter />
      </Form>
    </Segment>
  );
};

export default ProfileForm;
