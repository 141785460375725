import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpResult, HttpStatusCode, HttpTask } from '@core/http/model';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Lazy, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from '@shared/modules/range';
import { logSentryHttpError } from '@shared/modules/sentry/utils';
import history from '../../app-history';

import * as Retry from 'retry-ts';
import { retrying } from 'retry-ts/Task';

import * as AuthService from '@modules/auth/service';
import { hideApiDownIndicator, showApiDownIndicator } from '@core/http/components/ApiDownIndicator';

const filterRedirectLoginApi = ['/authenticate', '/user', '/password', '/account'];

function isWhiteListUrl<E = unknown>(err: HttpError<E>): boolean {
  return pipe(
    O.fromNullable(err.url),
    O.exists(url => url.includes('/authenticate/refresh') || !filterRedirectLoginApi.some(path => url.includes(path))),
  );
}

function sendRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E>;
function sendRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<AxiosResponse<R>, E>;

function sendRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<R | AxiosResponse<R>, E> {
  const onError = (err: unknown, status: Retry.RetryStatus): HttpError<E> => {
    const error = HttpError.fromAxiosError<E>(err as any);

    error.log();

    if (status.iterNumber === 0 && error.status >= 500) {
      logSentryHttpError(`[http] error ${error.status} on ${error.url} path`, error);
    }

    if (error.isDownError()) {
      showApiDownIndicator();
    }

    return error;
  };

  const transformRequest = (status: Retry.RetryStatus) =>
    pipe(
      TE.tryCatch(request, err => onError(err, status)),
      TE.map(res => (raw ? res : res.data)),
    );

  const shouldRetry = (res: HttpResult) =>
    pipe(
      EI.swap(res),
      EI.exists(err => err.isDownError()),
    );

  const startRequest = pipe(
    retrying(Retry.capDelay(2000, Retry.exponentialBackoff(500)), transformRequest, shouldRetry),
    T.chainFirstIOK(() => hideApiDownIndicator),
  );

  return pipe(
    startRequest,
    TE.orElse(err => {
      if (HttpStatusCode.UNAUTHORIZED === err.status && isWhiteListUrl(err)) {
        return pipe(
          AuthService.refreshToken(),
          TE.chainW(() => startRequest),
          TE.orElseFirstIOK(() => () => history.replace('/login')),
          TE.mapLeft(() => err),
        );
      } else {
        return TE.left(err);
      }
    }),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.get(url, config), raw);
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  cursor: RangeCursor,
  filter?: F,
  sort?: string,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...{
          start_index: cursor.startIndex,
          end_index: cursor.endIndex,
        },
        sort,
        ...filter,
      },
    }),
    TE.map(Range.fromRangeResult),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.post(url, data, config), raw);
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function put<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function put<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.put(url, data, config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.delete(url, config), raw);
}

export const httpService = {
  get,
  getRange,
  post,
  put,
  delete: del,
};
