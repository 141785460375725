import theme from '@styles/theme';

import { Colors, DefaultTheme, ThemeProps } from 'styled-components';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';

export const colors = pipe(
  theme.colors,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<keyof Colors, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.colors[key],
    }),
  ),
);
