import React, { createContext, PureComponent, useContext, useEffect } from 'react';

import PQueue from 'p-queue';
import {
  isoPendingUploadId,
  PendingUpload,
  PendingUploadId,
  PendingUploadState,
  ResourceFolderId,
} from '@modules/resource-manager/model';

import { v4 as uuid } from 'uuid';

import * as S from 'fp-ts/Set';
import * as Eq from 'fp-ts/Eq';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as TO from 'fp-ts/TaskOption';
import * as String from 'fp-ts/string';
import { constVoid, identity, Lazy, pipe } from 'fp-ts/function';

import * as ResourceManagerService from '@modules/resource-manager/service';
import { usePrevious } from '@shared/hooks/previous';
import { useDebouncedCallback } from 'use-debounce';

const MAX_CONCURRENT_UPLOAD = 5;

const eqPendingUploadFile = pipe(
  String.Eq,
  Eq.contramap<string, PendingUpload>(upload => upload.id),
);

function updateUploadState(
  uploads: Set<PendingUpload>,
  id: PendingUploadId,
  state: PendingUploadState,
): Set<PendingUpload> {
  return pipe(
    uploads,
    S.map(eqPendingUploadFile)(upload => (upload.id === id ? { ...upload, state } : upload)),
  );
}

interface ResourceUploaderContextValue {
  uploads: Set<PendingUpload>;
  addUpload: (file: File, folderId: ResourceFolderId) => void;
  cancelUpload: (upload: PendingUpload) => void;
}

const ResourceUploaderContext = createContext<ResourceUploaderContextValue>({
  uploads: S.empty,
  addUpload: constVoid,
  cancelUpload: constVoid,
});

interface UploaderProviderState {
  uploads: Set<PendingUpload>;
}

class UploaderProvider extends PureComponent<{}, UploaderProviderState> {
  state: UploaderProviderState = {
    uploads: S.empty,
  };

  private queue = new PQueue({ concurrency: MAX_CONCURRENT_UPLOAD });

  private handleAddUpload = (file: File, folderId: ResourceFolderId) => {
    const id = isoPendingUploadId.wrap(uuid());

    const upload: PendingUpload = {
      id,
      name: file.name,
      state: PendingUploadState.Pending,
    };

    this.setState(
      ({ uploads }) => ({
        uploads: S.insert(eqPendingUploadFile)(upload)(uploads),
      }),
      () => {
        const task = pipe(
          // Check upload already in queue (not cancelled)
          T.fromIO(() => pipe(this.state.uploads, S.elem(eqPendingUploadFile)(upload), O.fromPredicate(identity))),
          // Update state to Running
          TO.chainTaskK(() =>
            T.fromIO(() =>
              this.setState(({ uploads }) => ({ uploads: updateUploadState(uploads, id, PendingUploadState.Running) })),
            ),
          ),
          // Upload file
          TO.chainTaskK(() => ResourceManagerService.uploadResourceOnFolder(folderId, file)),
          // Error or delete if success
          TO.chainTaskK(res =>
            T.fromIO(() =>
              this.setState(({ uploads }) => ({
                uploads: pipe(
                  res,
                  EI.fold(
                    () => updateUploadState(uploads, id, PendingUploadState.Error),
                    () => pipe(uploads, S.remove(eqPendingUploadFile)(upload)),
                  ),
                ),
              })),
            ),
          ),
          T.map(constVoid),
        );

        this.queue.add(task);
      },
    );
  };

  private handleCancelUpload = (upload: PendingUpload) =>
    this.setState(({ uploads }) => ({
      uploads: pipe(uploads, S.remove(eqPendingUploadFile)(upload)),
    }));

  render() {
    const ctx: ResourceUploaderContextValue = {
      uploads: this.state.uploads,
      addUpload: this.handleAddUpload,
      cancelUpload: this.handleCancelUpload,
    };

    return <ResourceUploaderContext.Provider value={ctx}>{this.props.children}</ResourceUploaderContext.Provider>;
  }
}

export function useUploaderContext(): ResourceUploaderContextValue {
  return useContext(ResourceUploaderContext);
}

export function useUploaderRefresh(cb: Lazy<unknown>) {
  const { uploads } = useUploaderContext();

  const uploadLength = uploads.size;
  const oldUploadLength = usePrevious(uploads).size;

  const debouncedRefresh = useDebouncedCallback(cb, 500);

  useEffect(() => {
    if (uploadLength < oldUploadLength) {
      debouncedRefresh();
    }
  }, [debouncedRefresh, uploadLength, oldUploadLength]);
}

export default UploaderProvider;
