import React, { FC, useMemo } from 'react';

import * as Styled from './Nav.styles';

import logo from '@assets/logos/logo.svg';
import { Profile } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';

import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { navEntries } from '../../routes';
import { Icon, Loader, Menu } from 'semantic-ui-react';
import { sequenceS } from 'fp-ts/Apply';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { useSendTask } from '@core/http/hooks';

import * as SudoService from '@modules/sudo/service';
import { useAuthContext } from '@modules/auth/context';
import { useBooleanState } from '@shared/hooks/boolean';

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const history = useHistory();

  const [popupOpen, openPopup, closePopup] = useBooleanState();

  const { updateProfile } = useAuthContext();

  const [sudoLoading, discardSudo] = useSendTask(SudoService.discardSudo);

  const userLabel = pipe(
    sequenceS(O.Apply)({
      firstName: filterEmptyStringToOption(profile.firstname),
      lastName: filterEmptyStringToOption(profile.lastname),
    }),
    O.fold(
      () => profile.company_label,
      ({ firstName, lastName }) => `${firstName} ${lastName}`,
    ),
  );

  const entries = useMemo(
    () =>
      pipe(
        navEntries,
        A.filterMap(entry =>
          pipe(
            entry.entries,
            A.filter(entry => entry.canAccess(profile)),
            NEA.fromArray,
            O.map(entries => ({ ...entry, entries })),
          ),
        ),
      ),
    [profile],
  );

  const handleDiscardSudo = () =>
    pipe(
      discardSudo,
      TE.chainIOK(profile => () => {
        updateProfile(profile);

        history.replace('/companies', { ignorePrevent: true });
      }),
    )();

  return (
    <Styled.NavContainer>
      <Styled.NavHeader to="/">
        <img src={logo} width={170} height={102} alt="Logo Platform.Garden" />
      </Styled.NavHeader>

      <Styled.NavContent>
        {entries.map((entry, i) => (
          <div key={i}>
            {entry.title ? (
              <Styled.NavGroupHeader>
                <h5>{entry.title}</h5>
              </Styled.NavGroupHeader>
            ) : null}

            <Styled.NavGroupContent>
              {entry.entries.map((entry, j) => (
                <li key={j}>
                  <NavLink to={entry.path} isActive={entry.isActive} exact={entry.exact}>
                    {entry.title}
                  </NavLink>
                </li>
              ))}
            </Styled.NavGroupContent>
          </div>
        ))}
      </Styled.NavContent>

      <Styled.NavBottom>
        {profile.is_sudo ? (
          <Styled.NavBottomWarning>
            <Icon name="warning sign" color="red" />
            <p>Connecté en tant que :</p>
          </Styled.NavBottomWarning>
        ) : null}

        <Styled.NavBottomPopup
          open={popupOpen}
          onOpen={openPopup}
          onClose={closePopup}
          on="click"
          position="top right"
          basic
          trigger={
            <Styled.NavBottomContent>
              <Icon name="user" />
              <p>{userLabel}</p>
              <Icon name="setting" />
            </Styled.NavBottomContent>
          }>
          <Menu vertical secondary onClick={closePopup}>
            <Menu.Item as={Link} to="/profile">
              <Icon name="user" />
              Mon compte
            </Menu.Item>
            {profile.is_sudo ? (
              <Menu.Item disabled={sudoLoading} onClick={handleDiscardSudo}>
                <Loader active={sudoLoading} size="small" />
                <Icon name="arrow left" />
                Revenir à mon compte
              </Menu.Item>
            ) : null}
            <Menu.Item as={Link} to="/logout">
              <Icon name="sign out" />
              Se déconnecter
            </Menu.Item>
          </Menu>
        </Styled.NavBottomPopup>
      </Styled.NavBottom>
    </Styled.NavContainer>
  );
};

export default Nav;
