import { RangeCursor } from '@shared/modules/range';
import { SearchFilter } from '@shared/modules/filter';
import { HttpRange, httpService, HttpTask } from '@core/http';
import {
  ResourceDetail,
  ResourceFolder,
  ResourceFolderId,
  ResourceFolderParams,
  ResourceFolderRangeItem,
  ResourceId,
  UpdateResourceParams,
} from '@modules/resource-manager/model';
import { Crop } from 'react-image-crop';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const URI = '/resource-manager';

export function getResourceFolderRange(
  cursor: RangeCursor,
  filter?: SearchFilter,
): HttpRange<ResourceFolderRangeItem, SearchFilter> {
  // FIXME -> le server attends `filters`
  return httpService.getRange(`${URI}/folders`, cursor, {
    filters: filter?.search,
  } as any);
}

export function createResourceFolder(params: ResourceFolderParams): HttpRange {
  return httpService.post(`${URI}/folders/new`, params);
}

export function getResourceFolder(id: ResourceFolderId, filter?: SearchFilter): HttpTask<ResourceFolder> {
  return httpService.get(`${URI}/folders/${id}/resources/all`, { params: { filters: filter?.search } });
}

export function updateResourceFolder(id: ResourceFolderId, params: ResourceFolderParams): HttpTask {
  return httpService.post(`${URI}/folders/${id}`, params);
}

export function deleteResourceFolder(id: ResourceFolderId): HttpTask {
  return httpService.delete(`${URI}/folders/${id}`);
}

function getFormData(file: File): FormData {
  const formData = new FormData();

  formData.append('attachment', file);

  return formData;
}

export function uploadResourceOnFolder(id: ResourceFolderId, file: File): HttpTask<Array<ResourceDetail>> {
  return httpService.post(`${URI}/folders/${id}/upload`, getFormData(file));
}

export function uploadResourceOnDefaultFolder(file: File): HttpTask<Array<ResourceDetail>> {
  return httpService.post(`${URI}/folders/default/upload`, getFormData(file));
}

export function updateResource(id: ResourceId, params: UpdateResourceParams): HttpTask {
  return httpService.post(`${URI}/resources/${id}`, params);
}

export function copyCropResource(folderId: ResourceFolderId, id: ResourceId, params: Crop): HttpTask<ResourceDetail> {
  return httpService.post(`${URI}/folders/${folderId}/resources/${id}/crop`, params);
}

export function previewCropResource(id: ResourceId, params: Crop): HttpTask<string> {
  return pipe(
    httpService.post<Blob>(`${URI}/resources/${id}/crop`, params, { responseType: 'blob' }),
    TE.map(blob => window.URL.createObjectURL(blob)),
  );
}

export function deleteResource(id: ResourceId): HttpTask {
  return httpService.delete(`${URI}/resources/${id}`);
}

export function getDefaultImage(): HttpTask<ResourceDetail> {
  return httpService.get('/images/default');
}
