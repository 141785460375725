import { Newtype } from 'newtype-ts';
import { SemanticICONS } from 'semantic-ui-react';

export type NotificationId = Newtype<{ readonly ID: unique symbol }, number> & number;

export interface Notification {
  id: NotificationId;
  label: string;
  createdAt: string;
  viewed: boolean;
  meta?: NotificationMeta;
}

export interface NotificationMeta {
  id: string;
  type: NotificationMetaType;
}

export enum NotificationMetaType {
  News = 'news',
  Partnerships = 'partnerships',
  PartnerCatalog = 'partner_catalog',
  PartnerCatalogProduct = 'partner_catalog_product',
  ReportAlerts = 'report_alerts',
}

export const notificationMetaTypeIcon: Record<NotificationMetaType, SemanticICONS> = {
  [NotificationMetaType.News]: 'file alternate outline',
  [NotificationMetaType.Partnerships]: 'add user',
  [NotificationMetaType.PartnerCatalog]: 'newspaper outline',
  [NotificationMetaType.PartnerCatalogProduct]: 'cart',
  [NotificationMetaType.ReportAlerts]: 'bullhorn',
};
