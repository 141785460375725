import React, { FC, useCallback, useEffect } from 'react';
import { Button, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import { useNotificationsContext } from '@modules/notifications/context';
import { renderOptional } from '@shared/utils/render';

import * as Styled from './Notifications.styles';
import { defaultKeyMapper, defaultRangeCursor, RangeCursor, useRange, VirtualizedList } from '@shared/modules/range';

import * as NotificationsService from '@modules/notifications/service';
import NotificationRow from '@modules/notifications/components/row/NotificationRow';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { useDeleteTask } from '@core/http/hooks';

interface NotificationsPopupContentProps {
  refreshUnviewed: () => void;
}

const NotificationsPopupContent: FC<NotificationsPopupContentProps> = ({ refreshUnviewed }) => {
  const { range, handleLoadMore, handleRefreshIndex } = useRange(NotificationsService.getNotificationsRange, {});

  const [, deleteAllNotificatons] = useDeleteTask(NotificationsService.deleteAllNotifications);

  const onLoadMore = useCallback(
    (cursor: RangeCursor) =>
      pipe(
        () => handleLoadMore(cursor),
        T.chainIOK(() => refreshUnviewed),
      )(),
    [handleLoadMore, refreshUnviewed],
  );

  useEffect(() => {
    onLoadMore(defaultRangeCursor);
  }, [onLoadMore]);

  const handleDeleteAllNotifications = () =>
    pipe(
      () => deleteAllNotificatons(),
      TE.chainIOK(() => () => {
        handleRefreshIndex(0);
        refreshUnviewed();
      }),
    )();

  return (
    <Styled.NotificationsPopupContent>
      {range.loading ? (
        <Styled.NotificationsLoaderContainer>
          <Loader active inline="centered" />
        </Styled.NotificationsLoaderContainer>
      ) : range.total === 0 ? (
        <Styled.NotificationsEmptyContainer>
          <p>Aucune notification</p>
        </Styled.NotificationsEmptyContainer>
      ) : (
        <>
          <Styled.NotificationsDeleteAllButton onClick={handleDeleteAllNotifications}>
            Supprimer toutes les notifications
          </Styled.NotificationsDeleteAllButton>
          <div style={{ height: Math.min(7, range.total) * 80 }}>
            <VirtualizedList range={range} loadMore={onLoadMore} minRowHeight={80} keyMapper={defaultKeyMapper}>
              {(notification, index) => (
                <NotificationRow notification={notification} index={index} handleRefreshIndex={handleRefreshIndex} />
              )}
            </VirtualizedList>
          </div>
        </>
      )}
    </Styled.NotificationsPopupContent>
  );
};

const Notifications: FC = () => {
  const { unviewed, refreshUnviewed } = useNotificationsContext();

  return renderOptional(unviewed, unviewed => (
    <Popup
      basic
      on="click"
      position="bottom right"
      style={{ padding: 0, borderRadius: 0 }}
      trigger={
        <Button as={Styled.NotificationsButton} size="tiny" compact>
          <Icon name="bell" />
          Notifications
          {unviewed > 0 ? (
            <Label size="mini" color="red">
              {unviewed}
            </Label>
          ) : null}
        </Button>
      }>
      <NotificationsPopupContent refreshUnviewed={refreshUnviewed} />
    </Popup>
  ));
};

export default Notifications;
