import React, { FC } from 'react';
import {
  Notification,
  NotificationMeta,
  NotificationMetaType,
  notificationMetaTypeIcon,
} from '@modules/notifications/model';

import * as Styled from './NotificationRow.styles';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { Link } from 'react-router-dom';
import { renderOptional } from '@shared/utils/render';
import { useDeleteTask } from '@core/http/hooks';

import * as NotificationsService from '@modules/notifications/service';

function getNotificationLink(meta: NotificationMeta): string | null {
  switch (meta.type) {
    case NotificationMetaType.News:
      return `/news/feed/${meta.id}`;
    case NotificationMetaType.Partnerships:
      return '/partnerships';
    case NotificationMetaType.PartnerCatalog:
      return `/partners/catalogs/${meta.id}`;
    case NotificationMetaType.ReportAlerts:
      return `/report-alerts`;
    default:
      return null;
  }
}

interface NotificationContainerProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationContainer: FC<NotificationContainerProps> = ({ notification, onClick, children }) => {
  const link = pipe(O.fromNullable(notification.meta), O.chainNullableK(getNotificationLink));
  return renderOptional(
    link,
    link => (
      <Styled.NotificationContainer as={Link} to={link} onClick={onClick}>
        {children}
      </Styled.NotificationContainer>
    ),
    () => <Styled.NotificationContainer onClick={onClick}>{children}</Styled.NotificationContainer>,
  );
};

interface NotificationRowProps {
  notification: Notification;
  index: number;
  handleRefreshIndex: (index: number) => void;
}

const NotificationRow: FC<NotificationRowProps> = ({ notification, index, handleRefreshIndex }) => {
  const [, deleteNotification] = useDeleteTask(NotificationsService.deleteNotification, undefined, {}, notification.id);

  const icon = pipe(
    O.fromNullable(notification.meta),
    O.chainNullableK(meta => notificationMetaTypeIcon[meta.type]),
    O.getOrElse<SemanticICONS>(() => 'file outline'),
  );

  const handleNotificationClick = () =>
    pipe(
      () => deleteNotification(),
      TE.chainIOK(() => () => handleRefreshIndex(index)),
    )();

  return (
    <NotificationContainer notification={notification} onClick={handleNotificationClick}>
      <Icon name={icon} />

      <div>
        <p>{notification.createdAt}</p>
        <p>{notification.label}</p>
      </div>
    </NotificationContainer>
  );
};

export default NotificationRow;
