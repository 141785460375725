import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  height: 100%;
  padding: 10px 15px;
  border-top: 1px solid #ddd;
  color: inherit;
  background: #fff;
  transition: background-color 0.15s linear;

  &:hover {
    color: inherit;
  }

  a&:hover {
    background-color: #f2f2f2;
  }

  div {
    p:first-child {
      margin-bottom: 5px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;
