import React, { PureComponent, ReactNode } from 'react';
import { DEFAULT_RANGE_SIZE, defaultRangeCursor, VirtualizedListProps } from '@shared/modules/range';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  KeyMapper,
  List,
  ListRowProps,
} from 'react-virtualized';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import isEqual from 'lodash.isequal';

import * as Styled from './styles';
import { renderOptional } from '@shared/utils/render';

const MIN_ROW_HEIGHT = 60;

export class VirtualizedList<T> extends PureComponent<VirtualizedListProps<T>> {
  private readonly cache: CellMeasurerCache;

  private listRef: List | null = null;

  constructor(props: VirtualizedListProps<T>) {
    super(props);

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: props.minRowHeight ?? MIN_ROW_HEIGHT,
      keyMapper: this.cellMeasurerCacheKeyMapper,
    });
  }

  componentDidMount() {
    if (this.props.noFilter) {
      this.props.loadMore(defaultRangeCursor);
    }
  }

  componentDidUpdate(prevProps: Readonly<VirtualizedListProps<T>>) {
    if (!isEqual(prevProps.range.filter, this.props.range.filter)) {
      this.listRef?.scrollToRow(0);
    }

    if (prevProps.range.items !== this.props.range.items) {
      this.cache.clearAll();
      this.listRef?.forceUpdateGrid();
    }
  }

  private cellMeasurerCacheKeyMapper: KeyMapper = (rowIndex: number, columnIndex: number) =>
    pipe(
      O.fromNullable(this.props.keyMapper),
      O.chain(keyMapper =>
        pipe(
          this.props.range.get(rowIndex),
          O.map(item => keyMapper(item, rowIndex)),
        ),
      ),
      O.getOrElse<string | number>(
        () => `${rowIndex}-${columnIndex}-${this.props.range.has(rowIndex) ? 'loaded' : 'loading'}`,
      ),
    );

  private clearCellMeasurerCache = () => this.cache.clearAll();

  private isRowLoaded = ({ index }: Index): boolean => this.props.range.has(index);

  private loadMoreRows = ({ startIndex, stopIndex }: IndexRange) =>
    this.props.loadMore({ startIndex, endIndex: stopIndex });

  private noRowsRenderer = () =>
    this.props.range.loading ? (
      <></>
    ) : (
      <Styled.ListNoRowMessage>{this.props.emptyMessage ?? 'Aucune donnée à afficher.'}</Styled.ListNoRowMessage>
    );

  private rowRenderer = (props: ListRowProps): ReactNode => {
    return (
      <CellMeasurer key={props.key} cache={this.cache} columnIndex={0} rowIndex={props.index} parent={props.parent}>
        {({ registerChild }) => (
          <div ref={registerChild as any} style={props.style}>
            {renderOptional(this.props.range.get(props.index), item => this.props.children(item, props.index))}
          </div>
        )}
      </CellMeasurer>
    );
  };

  private registerListRef = (infiniteLoaderRegister: (ref: List | null) => void) => (ref: List | null) => {
    infiniteLoaderRegister(ref);
    this.listRef = ref;
  };

  render() {
    const { range, minRowHeight } = this.props;

    return (
      <Styled.VirtualizedTableContainer>
        <InfiniteLoader
          rowCount={range.total}
          loadMoreRows={this.loadMoreRows}
          isRowLoaded={this.isRowLoaded}
          minimumBatchSize={DEFAULT_RANGE_SIZE}
          threshold={20}>
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer onResize={this.clearCellMeasurerCache}>
              {({ width, height }) => (
                <List
                  ref={this.registerListRef(registerChild)}
                  deferredMeasurementCache={this.cache}
                  rowCount={range.total}
                  rowHeight={this.cache.rowHeight}
                  estimatedRowSize={minRowHeight ?? MIN_ROW_HEIGHT}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  noRowsRenderer={this.noRowsRenderer}
                  rowRenderer={this.rowRenderer}
                  style={{ outline: 'none', willChange: range.total === 0 ? 'unset' : 'transform' }}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </Styled.VirtualizedTableContainer>
    );
  }
}
