import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { configureSentry } from '@shared/modules/sentry/utils';

import '@styles/css';

import './polyfills';

configureSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
