import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const NoRowMessage = styled(Segment)`
  display: flex;
  align-items: center;
  height: 55px;
  border-radius: 0 0 5px 5px !important;
  box-shadow: none !important;
`;

export const ListNoRowMessage = styled.p`
  padding: 40px 0;
  text-align: center;
`;
