import React, { FC, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';
import { GlobalStyles } from '@styles/global';
import { Router } from 'react-router-dom';
import RootRoutes from './routes';
import { AuthContextProvider } from '@modules/auth/context';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import UploaderProvider from '@modules/resource-manager/uploader';
import { Loader } from '@googlemaps/js-api-loader';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import history from './app-history';
import config from './config';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

const App: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  // FIXME -> voir pourquoi on a des bugs google maps
  useEffect(() => {
    new Loader({
      apiKey: config.VITE_GOOGLE_KEY,
    })
      .load()
      .finally(() => setLoading(false));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastsProvider />

      <ApiDownIndicator />

      <UploaderProvider>
        {loading ? (
          <DebouncedLineLoader />
        ) : (
          <Router history={history}>
            <AuthContextProvider>
              <RootRoutes />
            </AuthContextProvider>
          </Router>
        )}
      </UploaderProvider>
    </ThemeProvider>
  );
};

export default App;
