import { PureComponent } from 'react';
import { connect, FormikValues } from 'formik';
import { FormikConnectProps } from '../model';

import isEqual from 'lodash.isequal';

interface FormikEffectsProps<Values> {
  onChange: (values: Values) => void;
}

class FormikEffects<Values = FormikValues> extends PureComponent<
  FormikEffectsProps<Values> & FormikConnectProps<Values>
> {
  componentDidUpdate(prevProps: Readonly<FormikEffectsProps<Values> & FormikConnectProps<Values>>): void {
    const { formik } = this.props;

    if (!isEqual(formik.values, prevProps.formik.values)) {
      this.props.onChange(formik.values);
    }
  }

  render() {
    return null;
  }
}

export default connect<FormikEffectsProps<any>, FormikValues>(FormikEffects);
