import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Profile, UserRole } from '@modules/auth/model';

import * as NotificationsService from '@modules/notifications/service';

import { constVoid, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';

const POLLING_INTERVAL = 30000;

const NOTIFICATIONS_ROLES: ReadonlyArray<UserRole> = [
  UserRole.SupplierAdmin,
  UserRole.SupplierTech,
  UserRole.SupplierMarketing,
  UserRole.SupplierCommunication,
  UserRole.AgrefAdmin,
  UserRole.FredonAdmin,
  UserRole.UnepAdmin,
];

export interface NotificationsContextValue {
  unviewed: O.Option<number>;
  refreshUnviewed: () => void;
}

export const NotificationContext = createContext<NotificationsContextValue>({
  unviewed: O.none,
  refreshUnviewed: constVoid,
});

export interface NotificationContextProviderProps {
  profile: Profile;
}

export const NotificationContextProvider: FC<NotificationContextProviderProps> = ({ profile, children }) => {
  const [unviewed, setUnviewed] = useState<O.Option<number>>(O.none);

  const fetchUnviewed = useCallback(() => {
    pipe(
      NotificationsService.countUnviewedNotifications(),
      T.chainIOK(res => () => setUnviewed(O.fromEither(res))),
    )();
  }, []);

  useEffect(() => {
    if (NOTIFICATIONS_ROLES.includes(profile.role)) {
      fetchUnviewed();

      const interval = setInterval(() => {
        fetchUnviewed();
      }, POLLING_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    } else {
      setUnviewed(O.none);
    }
  }, [profile.role, fetchUnviewed]);

  const ctx = useMemo<NotificationsContextValue>(
    () => ({
      unviewed,
      refreshUnviewed: fetchUnviewed,
    }),
    [unviewed, fetchUnviewed],
  );

  return <NotificationContext.Provider value={ctx}>{children}</NotificationContext.Provider>;
};

export function useNotificationsContext(): NotificationsContextValue {
  return useContext(NotificationContext);
}
