import { HttpRange, httpService, HttpTask } from '@core/http';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { RangeCursor } from '@shared/modules/range';
import { Notification, NotificationId } from '@modules/notifications/model';

const URI = '/notifications';

export function getNotificationsRange(cursor: RangeCursor): HttpRange<Notification> {
  return httpService.getRange(URI, cursor);
}

export function countUnviewedNotifications(): HttpTask<number> {
  return pipe(
    httpService.get<{ unviewed: number }>(`${URI}/count`),
    TE.map(({ unviewed }) => unviewed),
  );
}

export function deleteNotification(id: NotificationId): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function deleteAllNotifications(): HttpTask {
  return httpService.delete(URI);
}
