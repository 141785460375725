import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Lazy } from 'fp-ts/function';

export function useBooleanState(
  defaultState: boolean = false,
): [boolean, Lazy<void>, Lazy<void>, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(defaultState);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse, setValue];
}
