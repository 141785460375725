import React, { FC } from 'react';

import * as Styled from './Layout.styles';
import Nav from '@layout/nav/Nav';
import { Profile } from '@modules/auth/model';

interface LayoutProps {
  profile: Profile;
}

const Layout: FC<LayoutProps> = ({ profile, children }) => {
  return (
    <Styled.LayoutContainer>
      <Nav profile={profile} />
      <Styled.LayoutContent>{children}</Styled.LayoutContent>
    </Styled.LayoutContainer>
  );
};

export default Layout;
