import { createGlobalStyle } from 'styled-components';
import { datePickerOverwrite } from '@styles/global/date-picker';
import { colors } from '@styles/utils';
import { rcSliderOverwrite } from '@styles/global/rc-slider';

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: hidden;
  }

  #root {
    height: 100%;
    overflow: hidden;
  }

  ${datePickerOverwrite}
  ${rcSliderOverwrite}
  
  .primary-text {
    color: ${colors.primary} !important;
  }

  .ui.labeled.icon.button.multiline-button {
    padding: 18px 10px 5px 45px !important;
    margin: 0 0 0 7px !important;
    vertical-align: bottom !important;
    min-width: 100px;
    white-space: nowrap;
    text-align: left !important;
    
    > em {
      position: absolute;
      top: 5px;
      left: 45px !important;
      font-size: 0.75em;
      line-height: normal;
      opacity: 0.65;
    }
  }
`;
