import { iso, Newtype } from 'newtype-ts';

import * as Ord from 'fp-ts/Ord';
import * as Ordering from 'fp-ts/Ordering';
import { pipe } from 'fp-ts/function';
import { Crop } from 'react-image-crop';

export type ResourceFolderId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type ResourceId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type PendingUploadId = Newtype<{ readonly ID: unique symbol }, string> & string;

export const isoPendingUploadId = iso<PendingUploadId>();

export interface ResourceFolderRangeItem {
  id: ResourceFolderId;
  name: string;
  resources: number;
}

export interface ResourceFolder {
  id: ResourceFolderId;
  name: string;
  resources: Array<ResourceDetail>;
  totalResources: number;
}

export interface ResourceFolderParams {
  name: string;
}

export interface ResourceDetail {
  resourceId: ResourceId | null;
  resource: Resource | null;
  source: Resource | null;
  strategy: ResourceStrategy;
  // store blob url crop preview
  cropPreview?: string;
}

export interface Resource {
  id: ResourceId;
  name: string;
  url: ResourceUrls;
  type: ResourceType;
}

export interface ResourceStrategy extends Omit<Partial<Crop>, 'unit'> {
  type: 'server' | 'template';
  source: ResourceId | null;
}

export type ResourceUrlType = 'thumbnail' | 'mdpi_1x' | 'web' | 'cover-web' | 'xhdpi_2x' | 'xxhdpi_3x' | 'original';

export type ResourceUrls = Record<ResourceUrlType, string>;

export interface ResourceType {
  category: ResourceCategory;
  name: string;
}

export enum ResourceCategory {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  Pdf = 'pdf',
  Other = 'other',
}

export interface UpdateResourceParams {
  name: string;
}

export enum PendingUploadState {
  Pending = 'pending',
  Running = 'running',
  Error = 'error',
}

export interface PendingUpload {
  id: PendingUploadId;
  name: string;
  state: PendingUploadState;
}

export const resourceCategoryLabel: Record<ResourceCategory, string> = {
  [ResourceCategory.Image]: 'Images',
  [ResourceCategory.Audio]: 'Audio',
  [ResourceCategory.Video]: 'Vidéos',
  [ResourceCategory.Pdf]: 'PDF',
  [ResourceCategory.Other]: 'Autres',
};

export const resourceCategoryAccept: Record<ResourceCategory, Array<string>> = {
  [ResourceCategory.Image]: ['image/jpeg', 'image/png'],
  [ResourceCategory.Audio]: ['audio/*'],
  [ResourceCategory.Video]: ['video/*'],
  [ResourceCategory.Pdf]: ['application/pdf'],
  [ResourceCategory.Other]: [],
};

export const resourceCategories = [
  ResourceCategory.Image,
  ResourceCategory.Video,
  ResourceCategory.Pdf,
  ResourceCategory.Audio,
  ResourceCategory.Other,
];

export const ordResourceCategory = Ord.fromCompare<ResourceCategory>((a, b) =>
  Ordering.sign(resourceCategories.indexOf(a) - resourceCategories.indexOf(b)),
);

export const ordResourceByCategory = pipe(
  ordResourceCategory,
  Ord.contramap<ResourceCategory, Resource>(resource => resource.type.category),
);
