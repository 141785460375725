import { CompanyId } from '@modules/companies/model';
import { httpService, HttpTask } from '@core/http';
import { Profile } from '@modules/auth/model';

import * as AuthService from '@modules/auth/service';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const URI = '/sudo';

function execTaskAndFetchProfile(task: HttpTask): HttpTask<Profile> {
  return pipe(
    task,
    TE.chain(() => AuthService.getProfile()),
  );
}

export function connectWithSudo(companyId: CompanyId): HttpTask<Profile> {
  return execTaskAndFetchProfile(httpService.post(URI, { companyId }));
}

export function discardSudo(): HttpTask<Profile> {
  return execTaskAndFetchProfile(httpService.delete(URI));
}
