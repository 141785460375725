import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const NotificationsButton = styled(Button)`
  &.ui.button {
    position: relative;
    margin-left: 20px;

    .ui.label {
      position: absolute;
      bottom: -6px;
      left: -6px;
      z-index: 100;
      padding: 3px 5px;
      text-align: center;
    }
  }
`;

export const NotificationsPopupContent = styled.div`
  width: 500px;
`;

export const NotificationsLoaderContainer = styled.div`
  padding: 20px;
`;

export const NotificationsDeleteAllButton = styled.button`
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 7px 10px;
  color: #e64b3c;
  background-color: #fff;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const NotificationsEmptyContainer = styled.div`
  padding: 30px;
  font-size: 20px;
  text-align: center;
`;
