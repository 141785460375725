import React, { FC } from 'react';

import { Header, Modal } from 'semantic-ui-react';
import { SharedButton } from '@styles/shared';

interface FormikPreventLeaveModalProps {
  open: boolean;
  submitting: boolean;
  isLogout: boolean;
  onClose: () => void;
  onLeave: () => void;
  onSubmitAndLeave: () => void;
}

const FormikPreventLeaveModal: FC<FormikPreventLeaveModalProps> = ({
  open,
  submitting,
  isLogout,
  onClose,
  onLeave,
  onSubmitAndLeave,
}) => {
  return (
    <Modal open={open} onClose={onClose} closeIcon size="tiny">
      <Header content="Modifications en cours" />
      <Modal.Content>
        {isLogout ? (
          <p>Si vous vous déconnectez maintenant, vos modifications en cours ne seront pas enregistrées.</p>
        ) : (
          <p>Si vous quittez maintenant cette page, vos modifications en cours ne seront pas enregistrées.</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <SharedButton
          btnType="cancel"
          content={isLogout ? 'Se déconnecter maintenant' : 'Quitter maintenant'}
          onClick={onLeave}
          disabled={submitting}
        />
        <SharedButton
          btnType="save"
          content={isLogout ? 'Enregistrer et se déconnecter' : 'Enregister et quitter'}
          onClick={onSubmitAndLeave}
          loading={submitting}
          disabled={submitting}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default FormikPreventLeaveModal;
